import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import { SideMenu } from './SideMenu';
import { useTranslation } from 'react-i18next';
import profile from '../../assets/images/userGrey.png'
import logo from '../../assets/images/Logo_CCO_2.png'
import useIsMobile from '../../hooks/Helper/useIsMobile';

export const Header = () => {
    const languageOptions = [
        { value: 'ENG', label: 'English', flagUrl: 'https://raw.githubusercontent.com/jackiboy/flagpack/e3e5f8cbb180b0cc7bae73b386d6b6c6c979e3e6/flags/4x3/ac.svg' },
        { value: 'UR', label: 'اردو', flagUrl: 'https://raw.githubusercontent.com/jackiboy/flagpack/e3e5f8cbb180b0cc7bae73b386d6b6c6c979e3e6/flags/4x3/pk.svg' },
        { value : 'FR', label : 'French' , flagUrl : 'https://raw.githubusercontent.com/jackiboy/flagpack/e3e5f8cbb180b0cc7bae73b386d6b6c6c979e3e6/flags/4x3/fr.svg'}
        // Add more languages as needed
      ];
      
     
        const { i18n } = useTranslation();
        const [dropdownVisible, setDropdownVisible] = useState(false);
        const isMobile =useIsMobile();
        const changeLanguage = (selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
          localStorage.setItem('i18nextLng', selectedLanguage);
          setDropdownVisible(false);
        };
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();
    let activeAccount;

    if (accounts.length > 0) {
        activeAccount = accounts[0]
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        const logoutRequest = {
            account: activeAccount,
            postLogoutRedirectUri: "/",
          };
        instance.logoutRedirect(logoutRequest);
        localStorage.removeItem('msal.userData')


    };
    const [isDropDownActive, setDropDownActive] = useState(false);
    const [isSidbarActive, setSideBarActive] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsDashboard(location.pathname === '/dashboard');
    }, [location]);

    const toggleClass = () => {
        setDropDownActive(!isDropDownActive);
    };

    const toggleSidebarClass = () => {
        setSideBarActive(!isSidbarActive);
    };

    return (
        <div id="page-container">
            {activeAccount && (
            <div>
                <header className="cco_section cco_section_1">
                    <div className="cco_row cco_row_5">
{/*                     
                        <div className="cco_col cco_col_9">
                            
                            <div className="cco_btn cco_btn_4">
                                <button id="ccoSidebarToggle" onClick={toggleSidebarClass}>
                                    <span>Toggle Menu</span>
                                </button>
                            </div>
                        </div> */}

                        <div className="cco_col cco_col_10">
                            <div className="cco_img cco_img_0">
                                <a className="cco_img_wrap" href="/dashboard">
                                    <img src={logo} alt="NCCCO" />
                                </a>
                            
                            </div>
                            
                        </div>
                        {/* <div className="cco_col cco_col_12">
                            <div className={`cco_dropdown cco_dropdown_0 ${dropdownVisible? 'active' : null}`} onClick={() => setDropdownVisible(!dropdownVisible)} >
                                <button className={'cco_dropdown_toggle'}  >
                                <img
          src={languageOptions.find(option => option.value === i18n.language)?.flagUrl}
          alt={`${i18n.language}-flag`}
          className="flag-icon"
        /> &nbsp;<strong><h1 style={{ alignContent :'center' , display: 'flex' }}>{i18n.language}</h1></strong>
                                    </button>
                                    <div className="cco_dropdown_content ">
                                    {dropdownVisible && (
        <ul className="hover-list">
          {languageOptions.map((option , index) => (
          <div  key={index} >
            <li
              key={option.value}
              style={{ alignContent :'center' , display: 'flex' }} 
              onClick={() => changeLanguage(option.value)}
            >
            <img
                src={option.flagUrl}
                alt={`${option.value}-flag`}
               
              style={{ marginRight: "10px", height : '20px', width : '28px' }}/>&nbsp;<p style={{ alignContent :'center' , display: 'flex' }}>{option.label}</p>
            </li></div>
          ))}
        </ul>
      )}
                                    </div>
                                   </div> 
                            </div> */}
                        <div className="cco_col cco_col_11">
                            <div className={`cco_dropdown cco_dropdown_0 ${isDropDownActive ? 'active' : null}`} onClick={toggleClass}>
                                <button className={'cco_dropdown_toggle'}>

                                    <img 
                                    className='cco_dropdown_toggle_img '
                                        src={profile}
                                        alt=""
                                    />
                            <div className="dropdown_content">
                             
                                </div>
 
                <span className="cco_dropdown_toggle_text">
                                        {activeAccount?.username}
                                    </span>
                                </button>
                                

<div >
       
</div>
                                <div className="cco_dropdown_content">
                               
                                    {/* <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">{t('RoleManagement')}</div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_avatar">
                                          
                                            <Link to={"/testing-personnel"} state={activeAccount} >{t('Test')}</Link>

                                        </li>
                                    </ul>  */}
                                    <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">
                                           {t('Account')}
                                        </div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_gear">
                                         
                                            <Link to={"/account"} state={activeAccount} >{t('details')}</Link>
                                        </li>
                                        <li className="cco_menu_item cco_menu_item_icon_logout">
                                            <a href="javascript:;" onClick={handleLogoutRedirect}>{t('signout')}</a>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                           

                        </div>
                     
                    </div>
                </header></div>

            )}

            {!activeAccount && (
                <div className='loginPage'>
                    <header className="cco_header">
                        <div className="cco_header_container">
                            <div className="cco_header_logo">
                                <a href="/" rel='noreferrer'>
                                    <img
                                        src='sandbox1995.ccocert.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png'
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <div className="cco_header_nav">
                                <nav className="cco_header_nav_desktop" style={{ float: "right" }}>
                                    <ul className="cco_header_list_2">
                                        <li className="cco_header_list_2_item cco_header_list_2_item_register">
                                            <a href='javascript:void(0)' onClick={handleLoginRedirect} rel='noreferrer'>
                                                {'Login/Register'}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="cco_header_nav_mobile" style={{ float: 'right' }}>
                                    <div className="cco_header_nav_mobile_closed">
                                        <ul className="cco_header_list_4">
                                            <li className="cco_header_list_4_item cco_header_list_4_item_register">
                                                <a href='javascript:void(0)' onClick={handleLoginRedirect}>
                                                    {'Login/Register'}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Standby Access Section */}
                        {!isMobile && (
                <div className="cco_row cco_row_4">
                    <div className="cco_col">
                        <div style={{ fontSize: "30px", color: "red", textAlign: "center" ,marginTop: '-40px' }}>
                            <strong>STANDBY MODE</strong>
                        </div>
                    </div>
                </div>
            )}
                        {isMobile && (
                <div className="cco_row cco_row_0">
                    <div className="cco_col">
                        <div style={{ fontSize: "25px", color: "red", textAlign: "center" , marginTop: '10px' }}>
                            <strong>STANDBY MODE</strong>
                        </div>
                    </div>
                </div>
            )}
                
                    </header>
                </div>
            )}
            {/* {activeAccount && (
                <SideMenu isDashboard={isDashboard} isSidbarActive={isSidbarActive}/>
            )} */}





        </div>
      
    );
            

}

