import { RootCerts } from '../types/dashboard';
import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { sort } from 'fast-sort';

export const useCerts = () => {
    return useQuery<RootCerts, Error>(['certs'], async () => {
        const jsonResponse = await request({
            endpoint: 'GetCertifications',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        if (Array.isArray(jsonResponse.data)) {
            jsonResponse.data = sort(jsonResponse.data).desc((cert: any) => new Date(cert.ExpirationDate));
        }

        return jsonResponse;
    });
};
