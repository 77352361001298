const UR ={
      translation: {
        RoleManagement: 'کردار کا انتظام',
        Test : 'ذاتی جانچ',
        Account :'اکاؤنٹ مینجمنٹ',
        details :'اکاؤنٹس کی تفصیل',
        signout : 'باہر جائیں',
        dash :'ڈیش بورڈ',
        web : 'ویب سائٹ',
        contact:'ہم سے رابطہ کریں۔',
        login : 'لاگ ان کریں',
        hi : 'سلام',
        welcome : 'ڈیش بورڈ میں خوش آمدید',
        id : 'سی سی او آئی ڈی',
        phone : 'فون',
        email : 'ای میل',
        apply : 'ابھی درخواست دیں',
        learn : 'تحریری جانچ کے اختیارات کے بارے میں جانیں۔',
        candidate : 'امیدوار کے وسائل',
        heading : 'OPT یا EOT ٹیسٹنگ میں نئے ہیں؟ ایک ٹیسٹ ڈرائیو لے لو!',
        paragraph :'اپنے آپ کو CCO کے امتحان کی ترسیل کے نئے نظام سے آشنا کریں۔ CCO نے ایک مفت ڈیمو امتحان بنایا ہے جو آپ کو نیویگیٹ کرنے کی اجازت دیتا ہ۔  امتحان کی ترسیل کا نظام اور آن اسکرین کیلکولیٹر آزمائیں، لوڈ چارٹ پی ڈی ایف فائلوں کے ساتھ کام کرنا، جائزہ لینا/ جھنڈا لگانا سوالات، اور مزید.'
        , launch : 'ڈیمو امتحان شروع کریں۔',
        certificate : 'میرے سرٹیفیکیشنز',
        para2 : 'کوئی سرٹیفکیٹ نہیں ملا',
        schedule : 'آنے والے امتحانات / زیر التواء نتائج',
        para3 : 'کوئی آئندہ جائزے نہیں ملے',
        para4 : '*امتحان کے نتائج CCO کی درستگی اور دیگر مناسب اصلاحات کے ساتھ مشروط ہیں۔',
        para5 : 'تاریخ کا کوئی امتحان نہیں ملا',
        head2 : 'امتحان کی تاریخ*', 
        h2detail : 'پچھلے 12 ماہ'

      },
     
    }; export default UR