import React, { useEffect } from 'react';

interface IBannerProps{

    showBanner:boolean,
    message:string,
    background:string;
    color?:string;
    isPhotoBanner?:boolean;
    type?:string;
    isMobile?:boolean;
}

 export const NotificationBanner = ({
    showBanner,
    message,
    background,
    color,
    isPhotoBanner = false,
    type = '',
    isMobile = false,
}: IBannerProps) => {
    useEffect(() => {
        const sidebarElement = document.getElementById("ccoSidebar") as HTMLElement;
        if (sidebarElement) {
            sidebarElement.style.paddingTop = showBanner
                ? type === ''
                    ? "95px"
                    : isPhotoBanner && type === 'ac'
                    ? '140px'
                    : '80px'
                : '0px';
        }
    }, [showBanner, isPhotoBanner, type]);
    return (
        showBanner ? (
            <div className="custom-overlay">
                <div className="cco_row" style={{ marginBottom: '20px',marginTop:isPhotoBanner && type==='ac'?'55px':(isPhotoBanner && type==='ac' && isMobile)?'70px':'' }}>
                    <div className="cco_col">
                        <div className={`cco_text cco_text_${background}`} style={{color:color?color:''}}>
                            <div className="cco_text_inner">
                                <p>{message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)

    );
};