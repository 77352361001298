const ENG= {
  
      translation: {
        RoleManagement: 'Role Management',
        Test: 'Testing Personal',
        Account : 'Account management',
        details :'Account details',
        signout :'Sign out',
        dash : 'Dashboard',
        web :'Website',
        contact : 'Contact us',
        login : 'Log in',
        hi :'Hi',
        welcome : 'Welcome to the Dashboard' ,
        id : ' CCO ID',
        phone : 'Phone',
        email : 'Email',
        apply : 'Apply Now For',
        learn : 'Learn about written testing options',
        candidate : 'Candidate Resources',
        heading :'New to OPT or EOT testing? Take a test drive!',
        paragraph : ' Familiarize yourself with CCO’s new exam delivery system. CCO has created a free demo exam allowing you to navigate then exam delivery system and try out the on-screen calculator, working with load chart PDF files, reviewing/flagging questions, and more.',
        launch : '   Launch Demo Exam',
        certificate : 'My Certifications',
        para2 : 'No certificates found',
        schedule : 'Upcoming Exams / Pending Results',
        para3 : 'No upcoming exams found',
        para4 : '*Examination results are subject to CCO determinations of validity and other appropriate corrections.',
        para5 : 'No history exams found',
        head2 : '  Exam History *' ,
        h2detail : 'previous 12 months',
        button1: 'Written Testing - EOT/OPT'
      },
  

    }; export default ENG;