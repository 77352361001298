const FR ={
    translation: {
        RoleManagement: 'Gestion des rôles ',
        Test : 'Test de personnalité',
        Account :'Gestion de compte',
        details :'Détails du compte',
        signout : 'se déconnecter',
        dash :'Tableau de bord',
        web : ' Site web',
        contact:' Contactez-nous  ',
        login : 'Se connecter  ',
        hi : 'Salut',
        welcome : 'Bienvenue sur le tableau de bord',
        id : 'ID CCO',
        phone : 'Téléphone',
        email : 'E-mail',
        apply : ' Postulez maintenant pour',
        learn : 'En savoir plus sur les options de tests écrits' ,
        candidate : "Ressources pour les candidats",
        heading : 'Vous débutez dans les tests OPT ou EOT ? Faites un essai routier !',
        paragraph :'Familiarisez-vous avec le nouveau système de délivrance des examens de CCO. CCO a créé un examen de démonstration gratuit vous permettant de naviguer dans système de délivrance dexamens et essayez la calculatrice à lécran, travaillez avec des fichiers PDF de diagramme de chargement, révisez/signalez questions, et plus encore.',
        launch : "Lancer l'examen de démonstration",
        certificate : 'Mes certificat',
        para2 : '`Aucun certificat trouvé' ,
        schedule : "Examens à venir / Résultats en attente",
        para3 : "Aucun examen à venir trouvé",
        para4 : "*Les résultats de l'examen sont sujets aux déterminations de validité du CCO et à d'autres corrections appropriées."
,       para5 : "Aucun examen d'histoire trouvé",
        head2 : "Historique des examens *",
        h2detail : '12 mois précédents'

      },
     
    
}; export default FR