import React, { useEffect, useState } from 'react';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { useCerts } from '../../hooks/useCerts';
import { useQuery } from '@tanstack/react-query';
import { request } from '../../services/request';
import { Root } from '../../types/dashboard';
import { customURLs, formatAddress, getToken, isTokenExpired } from '../../tools/utils';
import { RootExam } from '../../types/exams';
import { Loader } from '../../components/Loader';
import { Accordion, CertificateAccordion } from '../../components/Accordion';
import { PhotoNotificationBanner as PhotoAlertBanner } from '../../components/Common/NotificationBanner';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { useTranslation } from 'react-i18next';
import { AccessControl, useGlobalContext } from '../../hooks/Helper/GlobalContext';
import { NotificationBanner } from '../../components/NotificationBanner/NotificationBanner';
import { string } from 'yup';
import { ProfileCard } from '../../components/Dashboard/ProfileCard';
import { Button } from '../../components/Common/Button';

export const Dashboard = () => {
    const {CandidateAccessControl,CandidateBannerMessage,PhotoBannerMessage,CandidateBannerColor,Color}=useGlobalContext();
    const {REACT_APP_Exam_Hub_URL,REACT_APP_DEMO_Exam_URL} = process.env;
    const { t } = useTranslation();
     const isMobile =useIsMobile();
    const query = useUser();
    const certs = useCerts();
    const [isExpired, setIsExpired] = useState(false); 
    const userProfileQuery = useUserProfilePicture();
    const userProfileData = userProfileQuery.data?.data.Data;
    const token = getToken();
    const token_expiry= '';
    useEffect(() => {
        const checkTokenExpiration = () => {
        const isExpired = isTokenExpired(token_expiry);
        setIsExpired(isExpired);
        };
        const timer = setInterval(checkTokenExpiration, 5000); // Adjust the interval as needed
        return () => {
            clearInterval(timer); // Clean up the timer when the component unmounts
        };
    }, [token_expiry]);
    const exams = useQuery<RootExam, Error>(['exams'], async () => {
        const jsonResponse = await request({endpoint: 'Exams'});
        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }
        return jsonResponse;
    });
    const data = (query.data?.data || {}) as unknown as Root['data'];
    const objExams = (exams.data?.data || {}) as unknown as RootExam['data'];
    const dataCerts = (certs.data?.data || []) as unknown as Root['data']['Certificates'];
    const address = (data?.Addresses?.length || 0) > 0 ? formatAddress(data.Addresses[0]) : null;
    const [profilePictureLoading, setprofilePictureLoading] = useState(true);
    const isPhotoBannerVisible = query.isFetched && !query.isError && data && data.ContactPhotoRequest.length <= 0 && !data.myCCOPicture;
    const handleImageLoaded = () => {setprofilePictureLoading(false);};
    const handleIsExpiredClick = (event: any) => {
        if (isExpired) {
            event.preventDefault(); // Prevent the default action of the link
            window.location.reload(); // Token has expired, refresh the page
        }
    };
    //const userProfileData = userProfilePicture.data;
    useEffect(() => {
        const checkTokenExpiration = () => {
        const isExpired = isTokenExpired(token_expiry);
        setIsExpired(isExpired);
        };
        const timer = setInterval(checkTokenExpiration, 5000); // Adjust the interval as needed
        return () => {
            clearInterval(timer); // Clean up the timer when the component unmounts
        };
    }, [token_expiry]);


    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
        <article className="react-container"  >
        {/* NotificationBanner */}
        <NotificationBanner background="yellow" showBanner={query.isFetched &&!query.isError &&data && data.ContactPhotoRequest.length <= 0 &&!data.myCCOPicture}
             message={PhotoBannerMessage}
             isMobile={isMobile}
             color='black'
              />
        {/* <NotificationBanner
                background={CandidateBannerColor}
                showBanner={CandidateAccessControl ? true : false}
                message={CandidateBannerMessage}
                isPhotoBanner={isPhotoBannerVisible}
                type="ac"
                color={Color}
                isMobile={isMobile}
            /> */}
        <div className={ (query.isFetched &&!query.isError &&data &&data.ContactPhotoRequest.length <= 0 &&!data.myCCOPicture) ||CandidateAccessControl ? 'cco_section bannerVisible': 'cco_section cco_section_3' } >

{/* Intro div            */}
                {CandidateAccessControl !== 'Locked' && (
                    <>
                        <div className="cco_row cco_row_4">
                            <div className="cco_col">
                                <div className="cco_text cco_text_0">
                                    <div className="cco_text_inner">
                                    <strong>{t('hi')} {data && data?.Name?.First},</strong> {t('welcome')}
                                    </div>
                                </div>
                            </div>
                        </div>

{/* Responsive heading for standby  */}

                        <div className={`cco_row ${isMobile?'cco_row_0':'cco_row_4'}`}>
                           <div className="cco_col">
                             <div style={{ fontSize: "30px", color: "red", textAlign: "center"  }}>
                               <strong>STANDBY ACCESS</strong>
                             </div>
                           </div>
                        </div>
            
{/* Profile Picture Card */}
                       <ProfileCard
                         CandidateAccessControl={CandidateAccessControl}
                          query={query}
                          />
                 
  {/* condition - profile is completed show below section                      */}

                        {data.IsProfileCompleted && (
                            <>
{/* section for the apply now */}
                                <div className="cco_row cco_row_2">
                                    <div className="cco_col">
                                        <div className="cco_text cco_text_2">
                                            <div className="cco_text_inner">
                                            <h2>{t('apply')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cco_row cco_row_1">
                                    <div className="cco_col">
                                        <ul className="cco_menu cco_menu_0">
 {/* written test button */}
                                            <li className="cco_menu_item cco_menu_item_primary" style={{ marginBottom: '8px' }}>
                                                <Button 
                                                className={`${ CandidateAccessControl === 'Suspended' ? 'btn-disabled' : ''  }`}
                                                onClick={handleIsExpiredClick}
                                                text={customURLs?.onlineWrittenTestingUrl ?.button_text || 'Button Text'}
                                                href={`${token && customURLs?.onlineWrittenTestingUrl?.button_url && !isExpired && CandidateAccessControl !== 'Suspended'? customURLs.onlineWrittenTestingUrl.button_url + '?id_token=' + token: '#' }`}
                                                target={!isExpired && CandidateAccessControl !== 'Suspended'? '_blank': ''}
                                                />
                                            </li>
{/* practical button */}
                                            <li className="cco_menu_item cco_menu_item_primary"style={{ marginBottom: '8px' }}>
                                              <Button
                                               className={`${CandidateAccessControl === 'Suspended' ? 'btn-disabled' : '' }`}
                                               onClick={handleIsExpiredClick}
                                               text={customURLs?.practicalTestingUrl?.button_text ||'Button Text'}
                                               href={`${ token &&customURLs?.practicalTestingUrl?.button_url && !isExpired && CandidateAccessControl !== 'Suspended'?customURLs.practicalTestingUrl.button_url + '?id_token=' + token: '#'}`}
                                               target={!isExpired && CandidateAccessControl !== 'Suspended'   ? '_blank': '' }
                                                />
                                           
                                            </li>
                                        </ul>
{/* link for learn testing option */}
                                        <div style={{ paddingLeft: isMobile ? '4rem' : '13rem' }}>
                                            <span>
                                                <Button
                                                className='ccolinks'
                                                text= {t('learn')}
                                                href={'https://www.nccco.org/testingoptions'}
                                                target='_blank'
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
 {/* section of Candidate Resources */}
                                <div className="cco_row cco_row_2">
                                    <div className="cco_col">
                                        <div className="cco_text cco_text_2">
                                            <div className="cco_text_inner">
                                            <h2>{t('candidate')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
{/* Card of Exam Demo */}
                                <div className="cco_row cco_row_11">
                                    <div className="cco_col">
                                        <div className="cco_text cco_text_9">
                                            <div className="cco_text_inner">
                                            <h3>Test drive CCO's demo exam</h3>
                                            <p>So that you are prepared for test day, familiarize yourself with CCO's exam delivery system. You can get comfortable with the exam layout, try out the on-screen calculator, navigate on-screen load charts, and more.</p>
                                            </div>
                                        </div>
                                        <div className="cco_btn cco_btn_6">
                                        <Button
                                          className={`${CandidateAccessControl === 'Suspended' ? 'btn-disabled' : ''}`}
                                          text= {t('launch')}
                                          href={CandidateAccessControl !== 'Suspended'? REACT_APP_DEMO_Exam_URL: '#'}
                                          target={CandidateAccessControl !== 'Suspended' ? `_blank` : ''}
                                        
                                        />
                                        </div>
                                    </div>
                                </div>
     {/* Card for Exam Hub */}
                                <div className="cco_row cco_row_11">
                                    <div className="cco_col">
                                        <div className="cco_text cco_text_9">
                                            <div className="cco_text_inner">
                                                <h3>myCCO Exam Hub</h3>
                                                <p >Inside the myCCO Hub, you can:</p><br/>
                                                <ul  style={{ marginLeft: !isMobile ? '10px' : '' }}>
                                                   <li>○ Find written and practical test locations</li>
                                                   <li>○ See your exams that are available for scheduling</li>
                                                   <li>○ Schedule/Change exam appointments</li>
                                                   <li>○ View your Exam Results</li>
                                                 </ul>
                                            </div>
                                        </div>
                                        <div className="cco_btn cco_btn_6">
                                            <Button
                                             className={`${CandidateAccessControl === 'Suspended' ? 'btn-disabled' : ''}`}
                                             text= {customURLs?.esoCandidateDashboardUrl?.button_text || 'Button Text'}
                                             href={`${token && REACT_APP_Exam_Hub_URL && CandidateAccessControl !== 'Suspended' ? REACT_APP_Exam_Hub_URL +  '?id_token=' +token: '#'}`}
                                             target={CandidateAccessControl !== 'Suspended' ? `_blank` : ''}
                                             />
                                        </div>
                                    </div>
                                </div>
      {/* Card for testing Personnel */}
                               <div className="cco_row cco_row_11">
                                     <div className="cco_col">
                                        <div className="cco_text cco_text_2">
                                           <div className="cco_text_inner">
                                             <h3> For Testing Personnel only</h3>
                                               <p>Candidates CANNOT access this section of myCCO. Only credentialed TSCs, TACs, or Proctors (EOT and Practical) can access this portal.</p>
                                          </div>
                                        </div>
                                        <div className="cco_btn cco_btn_6">
                                        <Button
                                        className={`${CandidateAccessControl === 'Suspended' ? 'btn-disabled' : ''}`}
                                        text=   {customURLs?.manageMyTestAdministrationsUrl?.button_text || 'Button Text'}
                                        href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url? customURLs.manageMyTestAdministrationsUrl.button_url + '?id_token=' + token: '#'}`}
                                        target={CandidateAccessControl !== 'Suspended' ? `_blank` : ''}
                                       />
                                       </div>   
                                   </div>
                              </div>
                          </>
                        )}
                   
                        {/* <div className="cco_row cco_row_2">
                            <div className="cco_col">
                                <div className="cco_text cco_text_2">
                                    <div className="cco_text_inner">
                                    <h2>{t('certificate')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cco_row cco_row_3">
                            <div className="cco_col">
                                {certs.isLoading || query.isLoading ? (
                                    <Loader />
                                ) : certs.error || query.error ? (
                                    <p>
                                        {certs.error
                                            ? certs.error.message
                                            : query.error
                                            ? query.error.message
                                            : 'Unknown error'}
                                    </p>
                                ) : dataCerts.length > 0 ? (
                                    <ul className="cco_accordion">
                                        {dataCerts.map((certificate, id) => (
                                            <CertificateAccordion
                                                key={id}
                                                certificate={certificate}
                                                name={data.Name.FullName || null}
                                            />
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{t('para2')}</p>
                                )}
                            </div>
                        </div> */}

                        {/* <div className="cco_row cco_row_7">
                            <div className="cco_col cco_col_6">
                                <div className="cco_text cco_text_10">
                                    <div className="cco_text_inner">
                                    <h2>{t('schedule')}</h2>
                                    </div>
                                </div>
                            </div> */}

                            {/* {data.IsProfileCompleted && (
                                <div className="cco_col cco_col_7">
                                    <div className="cco_btn cco_btn_3"> */}
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        {/* <a
                                            className={`${CandidateAccessControl === 'Suspended' ? 'btn-disabled' : ''}`}
                                            href={`${
                                                token &&
                                                customURLs?.esoCandidateDashboardUrl?.button_url &&
                                                CandidateAccessControl !== 'Suspended'
                                                    ? customURLs.esoCandidateDashboardUrl.button_url +
                                                      '?id_token=' +
                                                      token
                                                    : '#'
                                            }`}
                                            target={CandidateAccessControl !== 'Suspended' ? `_blank` : ''}
                                        >
                                            {customURLs?.esoCandidateDashboardUrl?.button_text ||
                                                'Button Text'}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div> */}

                        {/* <div className="cco_row cco_row_3">
                            <div className="cco_col">
                                {exams.isLoading ? (
                                    <Loader />
                                ) : exams.error ? (
                                    <p>{exams.error.message}</p>
                                ) : objExams.upcomingExams.length > 0 ? (
                                    <ul className="cco_accordion">
                                        {objExams.upcomingExams.map((exam, index) => (
                                            <Accordion key={index} exam={exam} />
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{t('para3')}</p>
                                )}
                            </div>
                        </div> */}

                        {/* <div className="cco_row cco_row_2">
                            <div className="cco_col">
                                <div className="cco_text cco_text_2">
                                    <div className="cco_text_inner">
                                        <h2>
                                        {t('head2')} <small>({t('h2detail')})</small>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="cco_row cco_row_10">
                            <div className="cco_col">
                                {exams.isLoading ? (
                                    <Loader />
                                ) : exams.error ? (
                                    <p>{exams.error.message}</p>
                                ) : objExams.examHistory.length > 0 ? (
                                    <ul className="cco_accordion">
                                        {objExams.examHistory.map((exam, index) => (
                                            <Accordion key={index} exam={exam} />
                                        ))}
                                    </ul>
                                ) : (
                                    <p>{t('para5')}</p>
                                )}
                            </div>
                        </div> */}

                        {/* <div className="cco_row cco_row_1">
                            <div className="cco_col">
                                <div className="cco_text cco_text_15">
                                    <div className="cco_text_inner">
                                        <p>
                                        <strong>{t('para4')}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </>
                )}
            </div>
        

            </article>
    
            <div className="cco_section cco_section_4">
                <div className="cco_row">
                    <div className="cco_col">
                        <div className="cco_ad cco_ad_0">
                            <ins data-revive-zoneid="15248" data-revive-id="727bec5e09208690b050ccfc6a45d384"></ins>
                        </div>
                        <div className="cco_ad cco_ad_1">
                            <ins data-revive-zoneid="15249" data-revive-id="727bec5e09208690b050ccfc6a45d384"></ins>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

