import React from 'react';
import displayPicture from '../../assets/images/displaypage.png'
export const Login = () => {

    return (
        <div id="main-content">
            <article id="post-43" className="post-43 page type-page status-publish hentry">
                <div className="entry-content">
                    <div className="et-l et-l--post">
                        <div className="et_builder_inner_content et_pb_gutters3"><div className="et_pb_section et_pb_section_0 cco_et_section_0 et_pb_with_background et_section_specialty">
                            <div className="et_pb_row">
                                <div className="et_pb_column et_pb_column_1_2 et_pb_column_0  cco_et_col_0 et_pb_specialty_column  et_pb_css_mix_blend_mode_passthrough">
                                    <div className="et_pb_row_inner et_pb_row_inner_0 cco_et_row_0">
                                        <div className="et_pb_column et_pb_column_4_4 et_pb_column_inner et_pb_column_inner_0 et-last-child">
                                            <div className="et_pb_module et_pb_text et_pb_text_0 cco_et_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                                                <div className="et_pb_text_inner"><h1>Welcome to the myCCO Portal!</h1>
                                                    <h2>Login or register for a myCCO Portal account to:</h2>
                                                    <ul>
                                                        <li>Submit an exam application</li>
                                                        <li>Update your contact information</li>
                                                        <li>See exam results and current certifications</li>
                                                    </ul>
                                                    <p>Create an account in just a few easy steps. Watch a step-by-step video on how to register as an <a href="https://www.nccco.org/existing" target="_blank" rel="noreferrer"><span className="text">existing candidate</span></a> (someone who has tested with CCO in the past or is certified) or a <a href="https://www.nccco.org/newcandidate" target="_blank" rel="noreferrer"><span className="text">new user</span></a>.</p></div>
                                            </div>
                                        </div>

                                    </div>
                                </div><div className="et_pb_column et_pb_column_1_2 et_pb_column_1 cco_et_col_1 et_pb_css_mix_blend_mode_passthrough et_pb_column_single">
                                    <div className="et_pb_module et_pb_image et_pb_image_0 cco_et_img_0 et_multi_view__hover_selector">
                                        <span className="et_pb_image_wrap ">
                                            <img width="2556" height="1424" src={displayPicture} alt="" title="Laptop_2"  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 2556px, 100vw" className="wp-image-551 et_multi_view_swapped et_multi_view_image__loaded" data-et-multi-view="{&quot;schema&quot;:{&quot;attrs&quot;:{&quot;desktop&quot;:{&quot;src&quot;:&quot;https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2@2x.png&quot;,&quot;alt&quot;:&quot;&quot;,&quot;title&quot;:&quot;Laptop_2&quot;,&quot;class&quot;:&quot;wp-image-551&quot;,&quot;srcset&quot;:&quot;https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2@2x.png 2556w, https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2@2x-1280x713.png 1280w, https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2@2x-980x546.png 980w, https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2@2x-480x267.png 480w&quot;,&quot;sizes&quot;:&quot;(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 2556px, 100vw&quot;},&quot;tablet&quot;:{&quot;src&quot;:&quot;https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2.png&quot;,&quot;srcset&quot;:&quot;https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2.png 1278w, https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2-980x546.png 980w, https:\/\/portal-test.nccco.org\/wp-content\/uploads\/2022\/11\/Laptop_2-480x267.png 480w&quot;,&quot;sizes&quot;:&quot;(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1278px, 100vw&quot;}}},&quot;slug&quot;:&quot;et_pb_image&quot;}" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>


            </article>



        </div>
    );
}


