import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObjectAccessControl } from '../types/dashboard';
import { string } from 'yup';

export const useContactAccessControl = () => {
    return useQuery<RootObjectAccessControl, Error>(
        ['contactId'],
        async () => {
           
            const jsonResponse = await request({
                endpoint: 'GetContactAccessControl',
               
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        
    );
};
